.feedback-panel {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
  margin-bottom: 15px;
}

.feedback-panel-header {
  padding-bottom: 8px;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 15px;
}

span.badge {
  margin-right: 5px;
  margin-bottom: 5px;
}
