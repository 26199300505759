.center {
  text-align: center;
}

.right {
  text-align: right;
}

.feedback-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
}

.feedback-container-header {
  border-bottom: 1px solid #dedede;
}

.feedback-container-header h4 {
  font-weight: 700;
}

.feedback-container-content label {
  font-weight: 700;
}
