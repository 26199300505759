.center {
  text-align: center;
}

.right {
  text-align: right;
}

.training-element {
  margin: 5px 0px;
  padding: 10px 0px;
}

.training-element:nth-child(even) {
  background-color: #efefef;
}

.trainings-container {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}

.training-element-title {
  font-weight: bold;
  margin-bottom: 0px;
}

.training-element-category {
  font-size: 12px;
}
