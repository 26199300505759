.right {
  text-align: right;
}

.center {
  text-align: center;
}

.header {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px 4px 0px 0px;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.header h5 {
  margin-bottom: 0px;
}

.selected-people {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 0px 0px 4px 4px;
  padding: 20px;
}

.person {
  padding: 15px;
}

.person:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.person h5,
.person p {
  margin-bottom: 0px;
}

.category-buttons {
  margin-top: 15px;
}

.category-buttons button {
  margin-right: 10px;
}

.people-pool {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.people-pool-item {
  padding: 15px 15px;
}

.people-pool-item:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.people-pool-item h5 {
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 0px;
}

.people-pool-item h6 {
  font-weight: 400;
  color: #999;
  margin-bottom: 0px;
}

svg:hover {
  cursor: pointer;
}

button.primary {
  background-color: #2897dd;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger {
  background-color: #727070;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger:hover {
  background-color: #555555;
  border: none;
}
