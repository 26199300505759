.center {
  text-align: center;
}

.objective {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.objective-header {
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
}

.objective-header h5 {
  font-weight: 700;
  margin-bottom: 0.7rem;
}

.objective-user-feedback h6 {
  font-weight: 700;
}

.objective-user-feedback p {
  color: #555;
  margin-bottom: 0px;
}

.objective-approver-feedback h6 {
  font-weight: 700;
}

.objective-approver-feedback p {
  color: #555;
  margin-bottom: 0px;
}

.objective-grade p {
  font-weight: 700;
  font-size: 2rem;
}
