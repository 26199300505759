.center {
  text-align: center;
}

.nine-box-person-header h5 {
  margin-bottom: 0px;
  font-weight: 700;
}

.nine-box-person-header p {
  margin-bottom: 0px;
  color: #555;
}

.talent-grade {
  font-weight: 700;
  font-size: 1.1rem;
}

.performance-grade {
  font-weight: 700;
  font-size: 1.1rem;
}

.talent-grade p {
  margin-bottom: 0px;
}

.performance-grade p {
  margin-bottom: 0px;
}

.nine-box-person {
  padding: 0.5rem;
}
.nine-box-person:nth-child(even) {
  background-color: #efefef;
}
