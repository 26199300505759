.main-menu {
  background-image: linear-gradient(#2897dd, #6cdca8);
  text-align: left;
  box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
  transition: 0.2s ease-in;
  padding-top: 30px;
  min-height: 100%;
}

.main-menu img {
  max-width: 150px;
  margin-bottom: 20px;
}

.main-menu a {
  color: #fff;
  padding: 15px 0px;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 300;
}

.main-menu a:hover,
.main-menu a:focus {
  color: #555;
}

.selected {
  color: #555;
}

.main-menu a svg {
  margin-left: 20px;
  margin-right: 8px;
}

.active-link {
  font-weight: 700;
  color: #000;
}
