.center {
  text-align: center;
}

.ranking-report-person:nth-child(even) {
  background-color: #f2f2f2;
}

.person-info p {
  margin-bottom: 0px;
}

.person-info h5 {
  font-weight: 700;
  margin-bottom: 0px;
}

.ranking-report-person {
  padding: 10px 0px 10px 30px;
}

.border-green {
  border-left: 5px solid rgba(29, 201, 183);
}

.border-gray {
  border-left: 5px solid rgb(108, 117, 125);
}

.border-red {
  border-left: 5px solid rgba(253, 39, 235);
}

.number h5 {
  font-weight: 700;
}

.grade h6 {
  font-weight: 700;
  font-size: 1.6rem;
}

.above {
  background-color: rgba(29, 201, 183, 0.3);
  color: rgba(29, 201, 183);
  border-radius: 5px;
  padding: 5px;
  font-size: 0.8rem;
  font-weight: 700;
}

.average {
  background-color: rgba(128, 128, 128, 0.3);
  color: rgba(128, 128, 128);
  border-radius: 5px;
  padding: 5px;
  font-size: 0.8rem;
  font-weight: 700;
}

.below {
  background-color: rgba(253, 39, 235, 0.3);
  color: rgba(253, 39, 235);
  border-radius: 5px;
  padding: 5px;
  font-size: 0.8rem;
  font-weight: 700;
}
