.fullfillment-validation {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.fullfillment-validation-header {
  border-bottom: solid 1px #efefef;
}

.fullfillment-validation-header h5 {
  font-weight: 700;
}

.fullfillment-validation-content .level {
  font-weight: 700;
}

.fullfillment-validation-content h5 {
  margin-bottom: 0px;
  font-weight: 700;
}

.fullfillment-validation-content label {
  font-weight: 700;
}

.level {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.user-score {
  color: #555;
}

.download-link {
  color: #007bff;
  cursor: pointer;
}
