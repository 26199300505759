.question-form {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.new-question-form-header h5 {
  font-weight: 700;
}

.right {
  text-align: right;
}
