.finalGrade {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}

.sectionTitle {
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
