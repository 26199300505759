.person-will-evaluate h5 {
  margin-bottom: 0px;
  font-weight: bold;
}

.person-will-evaluate p {
  margin-bottom: 0px;
  color: #727070;
}
.person-will-evaluate {
  padding: 10px;
}

.person-will-evaluate:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
