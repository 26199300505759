.strenghts {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.strenghts h6 {
  font-weight: 700;
}

.weaknessess {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.weaknessess h6 {
  font-weight: 700;
}

span.strength {
  background-color: rgba(29, 201, 183, 0.3);
  padding: 5px 10px;
  color: rgba(29, 201, 183, 1);
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 5px;
}

span.weakness {
  background-color: rgba(253, 57, 122, 0.3);
  padding: 5px 10px;
  color: rgba(253, 57, 122, 1);
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: 5px;
  display: inline-block;
}
