.profile-name > .nav-link {
  color: #fff;
  background-color: #49a8fc;
  border-radius: 50%;
  font-weight: bold;
  padding: 0.5rem;
}

a.drop-options {
  color: #727070;
  font-weight: bold;
}

a.drop-options:focus {
  color: #727070;
}

a.drop-options:hover {
  color: #727070;
  text-decoration: none;
}

.dropdown-toggle::before {
  display: none !important;
}
