.meeting {
  padding: 15px 5px;
}

.meeting p {
  margin-bottom: 0px;
}

.meeting-header a {
  padding: 0px;
}

.meeting-header a:hover {
  text-decoration: underline;
  color: #1e88e5;
}

.meeting-header h5 {
  margin-bottom: 0px;
  line-height: 1.2;
  color: #1e88e5;
  font-weight: 500;
}

.meeting-header p {
  margin: 0px;
  color: #777;
  font-weight: 700;
  font-size: 0.9rem;
}

.meeting-content {
  text-align: right;
}

.meeting:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.stakeholders {
  color: #999999;
}

h5.duedate {
  color: #999999;
}

span.waiting-meeting {
  background-color: rgba(29, 201, 183, 0.3);
  padding: 5px 10px;
  color: rgba(29, 201, 183, 1);
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

span.pending-meeting {
  background-color: rgba(128, 128, 128, 0.3);
  padding: 5px 10px;
  color: rgba(128, 128, 128, 1);
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

span.overdue-meeting {
  background-color: rgba(255, 193, 7, 0.3);
  padding: 5px 10px;
  color: rgba(255, 193, 7, 1);
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

span.completed-meeting {
  background-color: rgba(93, 120, 255, 0.3);
  padding: 5px 10px;
  color: rgba(93, 120, 255, 1);
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

.meeting-content button.dropdown-toggle {
  background-color: #727070;
  padding: 1px 6px;
  border: none;
}

.meeting-content .dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}

.meeting-content button.dropdown-toggle:focus {
  background-color: #444;
}

.meeting-content button.dropdown-toggle:hover {
  background-color: #444;
}
