.competency-panel {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.competency-panel-header {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.competency-panel-totals h5 {
  font-weight: 700;
}

.competency-panel-header h5 {
  font-weight: 700;
  margin-bottom: 0px;
}

.competency-panel-header p {
  margin-bottom: 0px;
}

.competency-panel-behaviors h5 {
  font-weight: 700;
  margin-bottom: 0px;
}

.competency-panel-behaviors p {
  color: #555;
  margin-bottom: 0px;
}

.blue-label {
  color: #5d78ff;
  background-color: rgba(93, 120, 255, 0.3);
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
}

.red-label {
  color: red;
  background-color: rgba(253, 57, 122, 0.1);
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
}

.yellow-label {
  color: #e5991f;
  background-color: rgba(255, 184, 34, 0.2);
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
}

.green-label {
  color: #0abb87;
  background-color: rgba(10, 187, 135, 0.3);
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
}

.white-label {
  color: #555;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
}

.behavior-details {
  margin: 10px 0px;
  padding: 5px 0px;
}

.behavior-details:nth-child(even) {
  background-color: #efefef;
}

.competency-panel-totals {
  margin-top: 20px;
  padding: 10px;
}

.table-header {
  font-size: 0.8rem;
  text-align: center;
}
