.person-evaluated {
  padding: 15px;
}

.person-evaluated h5 {
  margin-bottom: 0px;
  font-weight: 700;
}

.person-evaluated:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.person-evaluated p {
  margin-bottom: 0px;
  color: #999;
}
