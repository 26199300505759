.orgchart {
  background-color: #fff;
}
.orgchart-container {
  background-color: #fff;
}

.orgchart ul li .oc-node .oc-content {
  box-sizing: border-box;
  padding: 2px;
  width: 180px !important;
  font-size: 0.625rem;
  border: 1px solid rgba(217, 83, 79, 0.8);
  border-radius: 0 0 4px 4px;
  text-align: center;
  background-color: #fff;
  color: #333;
  text-overflow: ellipsis;
  white-space: normal !important;
}

.orgchart ul li .oc-node .oc-heading {
  box-sizing: border-box;
  padding: 2px;
  margin: 0;
  width: 180px !important;
  height: 20px;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  background-color: rgba(217, 83, 79, 0.8);
  color: #fff;
  border-radius: 4px 4px 0 0;
}
