.new-scale-option-container {
  margin: 20px 0px;
}

.new-scale-option-form {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}
