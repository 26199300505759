.training-map {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.training-map table tr td div {
  display: flex;
  align-items: center;
}

.userName {
  vertical-align: middle;
  font-weight: bold;
}

.pending {
  background-color: #fdcce5;
  width: 100%;
  height: 2rem;
  border-radius: 8px;
}

.completed {
  background-color: #8bd3c7;
  width: 100%;
  height: 2rem;
  border-radius: 8px;
}

.approved {
  background-color: rgba(93, 120, 255, 0.3);
  width: 100%;
  height: 2rem;
  border-radius: 8px;
}

.blank {
  background-color: #fff;
  width: 100%;
  height: 2rem;
  border-radius: 8px;
}

.training-map table {
  table-layout: fixed;
}

.training-map table thead th:first-child {
  width: 14rem;
}

.training-map table td,
.training-map table th {
  width: 8rem;
}
