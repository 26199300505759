.report {
  padding: 15px 5px;
}

.report-header a {
  padding: 0px;
}

.report-header a:hover {
  text-decoration: underline;
}

.report-header h5 {
  margin-bottom: 0px;
  line-height: 1.2;
  color: #1e88e5;
  font-weight: 500;
}

.report-header p {
  margin-bottom: 0px;
  color: grey;
  font-weight: 300;
}

.report-content {
  text-align: right;
}

.report:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.report-content button.dropdown-toggle {
  background-color: #727070;
  padding: 1px 6px;
  border: none;
}

.report-content .dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}

.report-content button.dropdown-toggle:focus {
  background-color: #444;
}

.report-content button.dropdown-toggle:hover {
  background-color: #444;
}
