.delete-rule {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.delete-rule-form p {
  margin-bottom: 0px;
}

.delete-rule h4 {
  font-weight: bold;
}
