.talent {
  text-align: center;
}

.talent h5,
.performance h5 {
  font-weight: 700;
}

.performance {
  text-align: center;
}

.nine-box-people {
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px 30px;
}
