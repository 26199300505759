.selected-behavior-container-header {
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 5px;
}

.selected-behavior-container-header h5 {
  font-weight: bold;
}

.selected-behavior-container {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  margin: 15px 0px;
  padding: 15px;
}

span.anchor {
  color: gray;
}

.selected-behavior-container p {
  margin-bottom: 0px;
}
