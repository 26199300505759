.report-person {
  padding: 15px 5px;
}

.report-person-header a {
  padding: 0px;
  text-decoration: none;
}

.report-person-header a:hover {
  text-decoration: underline;
}

.report-person-header h5 {
  margin-bottom: 0px;
  line-height: 1.2;
  color: #1e88e5;
  font-weight: 500;
}

.report-person-header p {
  margin-bottom: 0px;
  color: grey;
  font-weight: 300;
}

.report-person:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
