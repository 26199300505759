.general-data-panel {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.general-data h5 {
  font-weight: 700;
  margin: 0px;
}

.general-data p {
  color: #555;
  margin: 0px;
}

.grade-data p {
  font-weight: 700;
  font-size: 3rem;
}

.dropdown-toggle::after {
  display: none !important;
}

button.dropdown-toggle {
  background-color: #2897dd;
  border: none;
  padding: 5px 12px;
  margin-bottom: 10px;
}

.dropdown-menu {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
}

.dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}
