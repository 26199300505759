.delete-hobby {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.delete-hobby-form p {
  margin-bottom: 0px;
}

.delete-hobby h4 {
  font-weight: bold;
}
