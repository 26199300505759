.center {
  text-align: center;
}

.pending-obj-person {
  padding: 10px;
}

.pending-obj-person:nth-child(even) {
  background-color: #efefef;
}

.pending-obj-person-header h5 {
  margin-bottom: 0px;
  font-weight: 700;
  color: #444;
}

.pending-obj-person-header p {
  margin-bottom: 0px;
  color: #999;
}

.status {
  color: #999;
}

svg {
  cursor: pointer;
}
