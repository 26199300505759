.fullfillment-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.fullfillment-container-header h5 {
  font-weight: 700;
}

.fullfillment-container-content label {
  font-weight: 700;
}
