.new-mytraining-form {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 20px;
}

.new-mytraining-form button {
  margin-right: 10px;
}

.new-mytraining-header h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}

input[type="text"] {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

label {
  font-weight: bold;
}
