.edit-category-form {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 20px;
}

.edit-category-form button {
  margin-right: 10px;
}

.edit-category-header h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}

.dropdown-toggle::after {
  display: none !important;
}

.edit-category-header button.dropdown-toggle {
  background-color: #2897dd;
  border: none;
  padding: 5px 12px;
  margin-bottom: 10px;
}

.dropdown-menu {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
}

.dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}
