.person-will-evaluate-details {
  padding: 10px;
}

.person-will-evaluate-details p {
  margin-bottom: 0px;
  color: #727070;
}

.person-will-evaluate-details h5 {
  margin-bottom: 0px;
  font-weight: bold;
}

.person-will-evaluate-details:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.dropdown-toggle::after {
  display: none !important;
}

.person-will-evaluate-details button.dropdown-toggle {
  background-color: #727070;
  padding: 1px 6px;
  border: none;
}

.person-will-evaluate-details .dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}

.person-will-evaluate-details button.dropdown-toggle:focus {
  background-color: #444;
}

.person-will-evaluate-details button.dropdown-toggle:hover {
  background-color: #444;
}
