.no-padding-left {
  padding-left: 0px;
}

svg:hover {
  cursor: pointer;
}

.center {
  text-align: center;
}

.edit-profile-form {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 15px;
}

.edit-profile-form button {
  margin-right: 10px;
}

.edit-profile-header h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}

.edit-profile-buttons {
  margin-top: 20px;
}

.edit-profile-buttons button {
  margin-right: 10px;
}

.edit-profile-behaviors {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.edit-profile-behaviors label {
  font-weight: bold;
}

.edit-profile-trainings {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.edit-profile-trainings label {
  font-weight: bold;
}

button.primary {
  background-color: #2897dd;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger {
  background-color: #727070;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger:hover {
  background-color: #555555;
  border: none;
}
