.badges-container {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 30px;
}

.personal-info {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 30px;
  vertical-align: middle;
  font-size: 1.3rem;
  text-align: center;
}

.succesors-info {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 30px;
  font-size: 1.4rem;
}

.button-container button {
  margin-right: 10px;
}

button.primary {
  background-color: #2897dd;
  border: none;
}

button.danger {
  background-color: #727070;
  border: none;
}

button.danger:hover {
  background-color: #555555;
  border: none;
}
