.inReview-training {
  background-color: #fff;
  border-radius: 4px;
  border-top: 7px solid #0dcaf0;
  padding: 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.inReview-training button {
  background-color: #0dcaf0;
  border: none;
}

.inReview-training-date {
  color: #555;
}

.inReview-training-title {
  font-weight: 700;
}

.inReview-training-evaluatee {
  color: #555;
}

p.training-group {
  color: #555;
  font-weight: 700;
  padding: 0px;
  margin-bottom: 10px;
}
