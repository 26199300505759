.right {
  text-align: right;
}

.my-commitments-header h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}

.dropdown-toggle::after {
  display: none !important;
}

.my-commitments-content button.dropdown-toggle {
  background-color: #2897dd;
  border: none;
  padding: 5px 12px;
  margin-bottom: 10px;
}

.dropdown-menu {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
}

.dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}

.empty-commitments {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  margin: 0px;
  padding: 30px;
}

.empty-commitments h5 {
  margin-bottom: 0px;
  font-size: 1.5rem;
  color: #999;
}
