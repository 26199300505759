.edit-commitment-form {
    background-color: #fff;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border-radius: 4px;
    padding: 20px;
  }
  
  .edit-commitment-form button {
    margin-right: 10px;
  }
  
  .edit-commitment-header h2 {
    font-weight: 400;
    line-height: 1.2;
    color: #455a64;
    margin-bottom: 10px;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="text"] {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  
  button.primary {
    background-color: #2897dd;
    border: none;
    padding: 0.375rem 0.75rem;
  }
  
  button.danger {
    background-color: #727070;
    border: none;
    padding: 0.375rem 0.75rem;
  }
  
  button.danger:hover {
    background-color: #555555;
    border: none;
  }
  