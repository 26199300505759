.scale {
  padding: 15px 5px;
}

.scale-header {
  padding: 0px;
}

.scale-header-title h5 {
  margin-bottom: 0px;
  line-height: 1.2;
  font-weight: 500;
  padding-left: 10px;
}

.scale-header-description h5 {
  color: grey;
  font-weight: 300;
  font-size: 0.9rem;
  padding-left: 10px;
}

.scale-content {
  text-align: right;
}

.scale:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.scale-content button.dropdown-toggle {
  background-color: #727070;
  padding: 1px 6px;
  border: none;
}

.scale-content .dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}

.scale-content button.dropdown-toggle:focus {
  background-color: #444;
}

.scale-content button.dropdown-toggle:hover {
  background-color: #444;
}
