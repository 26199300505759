.edit-training-form {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 20px;
}

.edit-training-form button {
  margin-right: 10px;
}

.edit-training-header h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}
