Button.nine-box {
  padding: 25px;
  background-color: #efefef;
  border-radius: 5px;
  border: 1px solid #cccccc;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
  width: 150px;
  height: 150px;
}

Button.nine-box:hover {
  color: #555;
  border: 1px solid #3699ff !important;
  background-color: #e1f0ff !important;
}

Button.nine-box:focus {
  color: #555;
  border: 1px solid #3699ff !important;
  background-color: #e1f0ff !important;
}
