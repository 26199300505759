.selected-person {
  padding: 15px;
}

.selected-person h5 {
  margin-bottom: 0px;
  font-weight: 700;
}

.selected-person:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.selected-person p {
  margin-bottom: 0px;
  color: #999;
}
