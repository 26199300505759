.account {
  padding: 10px 5px;
}

.account-header a {
  padding: 0px;
}

.account-header a:hover {
  text-decoration: underline;
}

.account-header h5 {
  margin-bottom: 0px;
  line-height: 1.2;
  color: #1e88e5;
  font-weight: 500;
}

.account-content {
  text-align: right;
}

.account:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.account-content button.dropdown-toggle {
  background-color: #727070;
  padding: 1px 6px;
  border: none;
}

.account-content .dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}

.account-content button.dropdown-toggle:focus {
  background-color: #444;
}

.account-content button.dropdown-toggle:hover {
  background-color: #444;
}

h5.link-like{
  cursor: pointer;
}
