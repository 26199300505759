.fullfillment-validation-second {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.fullfillment-validation-second-header {
  border-bottom: solid 1px #efefef;
}

.fullfillment-validation-second-header h5 {
  font-weight: 700;
}

.fullfillment-validation-second-content .level {
  font-weight: 700;
}

.fullfillment-validation-second-content h5 {
  margin-bottom: 0px;
  font-weight: 700;
  color: #555;
}

.fullfillment-validation-second-content label {
  font-weight: 700;
}

.level {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}
