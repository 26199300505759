.document:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.document {
  padding-top: 5px;
  padding-bottom: 5px;
}

.download-link {
  color: #007bff;
  cursor: pointer;
}
