.center {
  text-align: center;
}

.dashboard-graph-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.competencias {
  border-top: 7px solid #2897dd;
}

.objetivos {
  border-top: 7px solid #59cbba;
}

.encuestas {
  border-top: 7px solid #6cdca8;
}

.dashboard-graph-header {
  padding: 20px 0px;
}

.dashboard-graph-header h6 {
  font-size: 1.3rem;
  font-weight: 700;
}
