.right {
  text-align: right;
}

.center {
  text-align: center;
}

.objective-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.objective-container-header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.objective-container-header svg {
  cursor: pointer;
}

.objective-container-header h4 {
  font-weight: 700;
}

.objective-container-range label {
  font-weight: 700;
}

.weightVal {
  font-size: 1.3rem;
  font-weight: 700;
}
