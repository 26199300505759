.questionnaire-tab {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.right {
  text-align: right;
}

.questionnaire-buttons button {
  margin-right: 10px;
}

.nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  cursor: pointer;
}

.nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

button.primary {
  background-color: #2897dd;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger {
  background-color: #727070;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger:hover {
  background-color: #555555;
  border: none;
}

button.info {
  background-color: #59cbba;
  border: none;
  padding: 0.375rem 0.75rem;
}
