.person-data {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.person-data p {
  margin-bottom: 5px;
}

.person-info h5 {
  font-weight: 700;
  margin-bottom: 0px;
}

.person-info h6 {
  color: #555;
  font-weight: 400;
  margin-top: 5px;
}

.center {
  text-align: center;
}

span.category-tag {
  background-color: rgba(93, 120, 255, 0.3);
  padding: 5px 10px;
  color: rgba(93, 120, 255, 1);
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 5px;
}

.right {
  text-align: right;
}

table tr td {
  padding: 5px !important;
}
