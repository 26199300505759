.commitment {
  padding: 15px 5px;
}

.commitment p {
  margin-bottom: 0px;
}

.commitment-header a {
  padding: 0px;
}

.commitment-header p {
  font-size: 0.85rem;
  font-weight: bold;
  color: #555;
}

.commitment-header a:hover {
  text-decoration: underline;
}

.commitment-header h5 {
  margin-bottom: 0px;
  line-height: 1.2;
  color: #1e88e5;
  font-weight: 500;
}

.commitment-content {
  text-align: right;
}

.commitment:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.stakeholders {
  color: #999999;
}

h5.duedate {
  color: #999999;
}

span.waiting-commitment {
  background-color: rgba(29, 201, 183, 0.3);
  padding: 5px 10px;
  color: rgba(29, 201, 183, 1);
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

span.pending-commitment {
  background-color: rgba(128, 128, 128, 0.3);
  padding: 5px 10px;
  color: rgba(128, 128, 128, 1);
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

span.overdue-commitment {
  background-color: rgba(255, 193, 7, 0.3);
  padding: 5px 10px;
  color: rgba(255, 193, 7, 1);
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

span.completed-commitment {
  background-color: rgba(93, 120, 255, 0.3);
  padding: 5px 10px;
  color: rgba(93, 120, 255, 1);
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

.commitment-content button.dropdown-toggle {
  background-color: #727070;
  padding: 1px 6px;
  border: none;
}

.commitment-content .dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}

.commitment-content button.dropdown-toggle:focus {
  background-color: #444;
}

.commitment-content button.dropdown-toggle:hover {
  background-color: #444;
}
