.center {
  text-align: center;
}

.right {
  text-align: right;
}

.step-container {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 10px 20px;
}

.step-container-header {
  border-bottom: 1px solid #dedede;
}

.step-container-content h4 {
  font-weight: 700;
}

.step-container-content p {
  font-weight: 400;
  color: #555;
}

.step-container-content ol {
  margin-left: 0px;
  padding-left: 1rem;
}

.step-container-content ol li {
  font-weight: 700;
  padding-left: 5px;
}

.step-container-content ol li:nth-child(even) {
  background-color: #efefef;
}

.step-container-content ol li p {
  margin-bottom: 5px;
}

.person:nth-child(even) {
  background-color: #efefef;
}

button.primary {
  background-color: #2897dd;
  border: none;
  padding: 0.375rem 0.75rem;
}

