.right {
  text-align: right;
}

.center {
  text-align: center;
}

.header {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px 4px 0px 0px;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.header h5 {
  margin-bottom: 0px;
  font-weight: 700;
}

.selected-people {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 0px 0px 4px 4px;
  padding: 20px;
}

.category-buttons {
  margin-top: 15px;
}

.category-buttons button {
  margin-right: 10px;
}

.people-pool {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

svg:hover {
  cursor: pointer;
}

svg.options {
  color: #777;
  font-size: 1.3rem;
}

button.primary {
  background-color: #2897dd;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger {
  background-color: #727070;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger:hover {
  background-color: #555555;
  border: none;
}

.dropdown-toggle::after {
  display: none !important;
}

button.dropdown-toggle {
  background-color: #2897dd;
  border: none;
  padding: 5px 12px;
}
