.objective-evaluation form button {
  margin-right: 10px;
}

.decline-comment {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}
