.delete-scale {
    background-color: #fff;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border-radius: 4px;
    padding: 20px;
  }
  
  .delete-scale-form p{
      margin-bottom: 0px;
  }
  
  .delete-scale h4{
    font-weight: bold;
  }
  