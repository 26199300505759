.pending-evaluation-competencias {
  background-color: #fff;
  border-radius: 4px;
  border-top: 7px solid #2897dd;
  padding: 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.pending-evaluation-competencias > h5.pending-evaluation-title {
  color: #2897dd;
}

.pending-evaluation-competencias .pending-evaluation-user {
  margin-bottom: 5.5rem;
}

.pending-evaluation-competencias button {
  background-color: #2897dd;
  border: none;
}

.pending-evaluation-objetivos {
  background-color: #fff;
  border-radius: 4px;
  border-top: solid 7px #59cbba;
  padding: 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.pending-evaluation-objetivos > h5.pending-evaluation-title {
  color: #59cbba;
}

.pending-evaluation-objetivos button {
  background-color: #59cbba;
  border: none;
}

.pending-evaluation-encuestas {
  background-color: #fff;
  border-radius: 4px;
  border-top: solid 7px #6cdca8;
  padding: 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.pending-evaluation-encuestas > h5.pending-evaluation-title {
  color: #6cdca8;
}

.pending-evaluation-encuestas .pending-evaluation-user {
  margin-bottom: 5.5rem;
}

.pending-evaluation-encuestas button {
  background-color: #6cdca8;
  border: none;
}

.pending-evaluation-date {
  color: #aaa;
  font-weight: 700;
}

.pending-evaluation-title {
  font-weight: 700;
}

.pending-evaluation-user {
  color: #aaa;
  font-weight: 700;
}

.pending-evaluation-status {
  color: #aaa;
  font-weight: 700;
  margin-bottom: 8px;
}
