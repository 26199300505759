.talent-map-container {
  overflow: visible;
  overflow-x: scroll !important;
  max-height: 1000px;
}

div.table-responsive {
  overflow-x: visible !important;
  -webkit-overflow-scrolling: touch;
}

.red-label {
  color: red;
  background-color: rgba(253, 57, 122, 0.1);
  padding: 5px;
  font-weight: 700;
  border-radius: 4px;
  text-align: center;
}

.yellow-label {
  background-color: rgba(255, 184, 34, 0.2);
  color: #e5991f;
  padding: 5px;
  font-weight: 700;
  border-radius: 4px;
  text-align: center;
}

.green-label {
  background-color: rgba(10, 187, 135, 0.3);
  color: #0abb87;
  padding: 5px;
  font-weight: 700;
  border-radius: 4px;
  text-align: center;
}

.blue-label {
  color: #5d78ff;
  background-color: rgba(93, 120, 255, 0.3);
  padding: 5px;
  font-weight: 700;
  border-radius: 4px;
  text-align: center;
}

.blank-label {
  color: #fff;
  background-color: rgba(255, 255, 255, 1);
  padding: 5px;
  font-weight: 700;
  border-radius: 4px;
  text-align: center;
}

col.name {
  min-width: 15rem;
  font-weight: 700;
}

col.gap {
  min-width: 8rem;
}

td.test:first-child {
  font-weight: 700;
}

.behavior-style {
  font-weight: 400;
}
