.right {
  text-align: right;
}

.mytrainings h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}

.sphere-icon {
  color: #1e88e5;
}
