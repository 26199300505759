.login {
  height: 100vh;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.login a {
  text-decoration: none;
  font-weight: 700;
}
