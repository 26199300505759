@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@300;400;700&display=swap");

body {
  background-color: #fbfbfb;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}

.no-padding {
  padding: 0px;
}

.full-height {
  min-height: 100vh;
}

.left {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
