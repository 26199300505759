.objectives-map-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;
}

.objectives-map-container p {
  display: inline-block;
}

.node {
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgb(82 63 105 / 15%);
  border-radius: 4px;
}

.node h6,
.node p {
  margin-bottom: 0px;
}

.hk--custom--select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 70%;
}

.hk--custom--select > .dropdown-toggle.bs-placeholder {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}

.objectives-map .orgchart ul li .oc-node .oc-content {
  box-sizing: border-box;
  padding: 2px;
  width: 180px;
  font-size: 0.625rem;
  border: 1px solid rgba(217, 83, 79, 0.8);
  border-radius: 0 0 4px 4px;
  text-align: center;
  background-color: #fff;
  color: #333;
  white-space: normal !important;
  text-wrap: wrap !important;
  height: auto !important;
}

.objectives-map .orgchart ul li .oc-node .oc-heading {
  box-sizing: border-box;
  padding: 2px;
  margin: 0;
  width: 180px;
  height: 20px;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  background-color: rgba(217, 83, 79, 0.8);
  color: #fff;
  border-radius: 4px 4px 0 0;
}
