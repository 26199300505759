.lower-border {
  border-bottom: 1px solid #e0e0e0;
}

.meeting-details {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.meeting-details-agenda {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 10px;
}

.meeting-details-agenda-items {
  margin-top: 10px;
}

.meeting-details-buttons {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

h3.section {
  font-size: 1.5rem;
  font-weight: bold;
}

p.comment {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 1rem;
}

.comment-container {
  margin: 10px 0px;
}

span.add-element {
  font-size: 1rem;
  font-weight: bold;
  color: #aaa;
  cursor: pointer;
  margin-bottom: 0px;
}

span.title {
  font-weight: bold;
}
