.new-category-form {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.new-category-form button {
  margin-right: 10px;
}

.new-category-header h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}

button.primary {
  background-color: #2897dd;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger {
  background-color: #727070;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger:hover {
  background-color: #555555;
  border: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.new-category-header button.dropdown-toggle {
  background-color: #2897dd;
  border: none;
  padding: 5px 12px;
  margin-bottom: 10px;
}

.dropdown-menu {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
}

.dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}
