.behavior {
  padding: 15px 5px;
  background-color: #fff;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}

/* .behavior:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
} */

.competency-name {
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}

.behavior h4 {
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 700;
}

.behavior-content {
  text-align: right;
}

.behavior-content button.dropdown-toggle {
  background-color: #727070;
  padding: 1px 6px;
  border: none;
}

.behavior-content .dropdown-menu a.dropdown-item {
  color: #727070;
  font-weight: bold;
}

.behavior-content button.dropdown-toggle:focus {
  background-color: #444;
}

.behavior-content button.dropdown-toggle:hover {
  background-color: #444;
}

.no-behaviors {
  text-align: center;
  padding: 15px 0;
  color: #ccc;
  font-size: 1.3rem;
  font-weight: 700;
}

.single-behavior:nth-child(odd) {
  background-color: #efefef;
}
