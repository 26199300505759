.right {
  text-align: right;
}

.center {
  text-align: center;
}

.edit-report-form {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px;
}

.edit-report-form button {
  margin-right: 10px;
}

.edit-report-header h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}

.edit-evaluation-selection-form-buttons button {
  margin-right: 10px;
}

button.primary {
  background-color: #2897dd;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger {
  background-color: #727070;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger:hover {
  background-color: #555555;
  border: none;
}

button.info {
  background-color: #59cbba;
  border: none;
  padding: 0.375rem 0.75rem;
}
