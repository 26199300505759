h4.performance {
  writing-mode: vertical-rl;
  padding-top: 210px;
  font-weight: 700;
}

h4.talent {
  margin-bottom: 20px;
  font-weight: 700;
}

.center {
  text-align: center;
}

.nine-box-report {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 4px;
  padding: 20px 20px;
  margin-bottom: 20px;
}
