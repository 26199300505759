.active-hobby {
  background-color: rgba(29, 201, 183, 0.3);
  padding: 5px 10px;
  border-radius: 10px;
  color: #59cbba;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.inactive-hobby {
  background-color: rgba(128, 128, 128, 0.3);
  padding: 5px 10px;
  border-radius: 10px;
  color: rgba(128, 128, 128, 1);
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
