.edit-behavior-form {
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 10%);
  border-radius: 4px;
  padding: 20px;
}

.edit-behavior-form button {
  margin-right: 10px;
}

.edit-behavior-header h2 {
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
  margin-bottom: 10px;
}

button.primary {
  background-color: #2897dd;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger {
  background-color: #727070;
  border: none;
  padding: 0.375rem 0.75rem;
}

button.danger:hover {
  background-color: #555555;
  border: none;
}
