.person-documents {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.person-documents h6 {
  font-weight: bold;
}
